window.require = require
window.jQuery = window.$ = require('jquery')


var Tripartite = require('tripartite')
/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

require('page-editor').configure()
